import React, { useEffect, useState } from 'react'
import './banner.css'
import {
  BannerIcon1,
  BannerIcon2,
  BannerImg,
  BannerLine,
} from '../../Assets/Images/Banner'
import style from '../Form/Form.module.css'
import ReactInputMask from 'react-input-mask'
import { useForm } from 'react-hook-form'
import { PhoneNumberUtil } from 'google-libphonenumber'
import { sendInTg } from '../../Constants'

const Banner = ({ form, id }) => {
  const isFirstForm = form === '1'

  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors, touchedFields },
  } = useForm({ mode: 'all' })

  const [phone, setPhone] = useState('')
  const [isBlurredPhone, setIsBlurredPhone] = useState(false)
  const [toastMessage, setToastMessage] = useState(null)

  // Функція перевірки валідності номера
  const isPhoneValid = (phone) => {
    const phoneUtil = PhoneNumberUtil.getInstance()
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
    } catch (error) {
      return false
    }
  }
  const isValid = isPhoneValid(phone)

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset()
      setPhone('')
      setIsBlurredPhone(false)
    }
  }, [isSubmitSuccessful, reset])

  // Видалення повідомлення через 5 секунд
  useEffect(() => {
    if (toastMessage) {
      const timer = setTimeout(() => {
        setToastMessage(null)
      }, 5000)
      return () => clearTimeout(timer)
    }
  }, [toastMessage])

  // Функція відправки форми
  const onSubmit = async ({ name }) => {
    if (!isPhoneValid(phone)) {
      setToastMessage({
        type: 'error',
        text: 'Будь ласка, введіть правильний номер телефону.',
      })
      return
    }

    setToastMessage({ type: 'pending', text: 'Відправка...' })

    let msg = { name: name, phone: phone }
    let resp = await sendInTg(msg)

    if (resp.ok) {
      setToastMessage({ type: 'success', text: 'Форма успішно надіслана!' })
    } else {
      setToastMessage({
        type: 'error',
        text: 'Щось пішло не так. Оновіть сторінку та спробуйте ще раз.',
      })
    }
  }

  const redBord = {
    borderBottom: '1px solid rgba(255,0,0, 1)',
  }
  const greenBord = isFirstForm
    ? {
        borderBottom: '1px solid rgba(159, 255, 159, 1)',
      }
    : {
        borderBottom: '1px solid #145D51',
      }
  return (
    <div className="customContainer">
      <div className="banner_content_block">
        <div>
          <img className="banner_img" src={BannerImg} alt="BannerImg" />
        </div>
        <div className="banner_content_right">
          <div>
            <p className="banner_main_text">
              <span className="banner_main_text_span">Комерційні</span> <br />{' '}
              приміщення з терасами
            </p>
          </div>
          <div className="banner_icon_blocks">
            <div className="banner_icon_block">
              <div className="banner_icon_top">
                <img
                  className="banner_icon"
                  src={BannerIcon1}
                  alt="BannerIcon1"
                />
                <p className="banner_icon_top_text">Площа</p>
              </div>
              <div>
                <p className="banner_icon_bottom_text">
                  від 38,40 м2 до 160,45 м2
                </p>
              </div>
            </div>
            <div>
              <img
                className="banner_icon_line"
                src={BannerLine}
                alt="BannerLine"
              />
            </div>
            <div className="banner_icon_block">
              <div className="banner_icon_top">
                <img
                  className="banner_icon"
                  src={BannerIcon2}
                  alt="BannerIcon2"
                />
                <p className="banner_icon_top_text">Розтермінування </p>
              </div>
              <div>
                <p className="banner_icon_bottom_text">
                  до вересня 2026-го року
                </p>
              </div>
            </div>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
              <div className={style.form_box}>
                <div
                  className={style.form_box__wrap}
                  style={{
                    ...(errors?.name && touchedFields?.name
                      ? redBord
                      : !errors?.name && touchedFields?.name
                      ? greenBord
                      : null),
                    ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
                  }}
                >
                  <input
                    type="text"
                    id={'name'}
                    placeholder="Імʼя"
                    className={style.form_box__wrap_inp}
                    style={isFirstForm ? {} : { color: '#145D51' }}
                    {...register('name', {
                      required: true,
                      minLength: 2,
                      maxLength: 30,
                    })}
                    autoComplete={'off'}
                  />
                </div>
              </div>

              <div className={style.form_box}>
                <div
                  className={style.form_box__wrap}
                  style={{
                    ...(!isValid && isBlurredPhone
                      ? redBord
                      : isValid && isBlurredPhone
                      ? greenBord
                      : null),
                    ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
                  }}
                >
                  <ReactInputMask
                    mask="+38 (099) 999-99-99"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    onBlur={() => setIsBlurredPhone(true)}
                    className={style.form_box__wrap_inp}
                    placeholder={'Номер телефону'}
                    style={isFirstForm ? {} : { color: '#145D51' }}
                  />
                </div>
              </div>
              <button className={style.form_btnnew}>Надіслати</button>
            </form>
            {toastMessage && (
              <div
                className={`${style.toast} ${
                  toastMessage.type === 'success'
                    ? style.toastSuccess
                    : toastMessage.type === 'error'
                    ? style.toastError
                    : style.toastPending
                }`}
              >
                {toastMessage.text}
              </div>
            )}
            <div>
              <p className="banner_bottom_form_text">
                Залишайте контактні данні щоб отримати індивідіуальну пропозицію
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner

// import { useEffect, useState } from 'react'
// import './banner.css'
// import {
//   BannerIcon1,
//   BannerIcon2,
//   BannerImg,
//   BannerLine,
// } from '../../Assets/Images/Banner'
// import style from '../Form/Form.module.css'
// import ReactInputMask from 'react-input-mask'
// import { useForm } from 'react-hook-form'
// import { PhoneNumberUtil } from 'google-libphonenumber'
// import { sendInTg } from '../../Constants'
//
// const Banner = ({ form, id }) => {
//   const isFirstForm = form === '1'
//
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { isSubmitSuccessful, errors, touchedFields },
//   } = useForm({ mode: 'all' })
//
//   const [phone, setPhone] = useState('')
//   const [isBlurredPhone, setIsBlurredPhone] = useState(false)
//   const [toastMessage, setToastMessage] = useState(null)
//
//   // Функція перевірки валідності номера
//   const isPhoneValid = (phone) => {
//     const phoneUtil = PhoneNumberUtil.getInstance()
//     try {
//       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
//     } catch (error) {
//       return false
//     }
//   }
//   const isValid = isPhoneValid(phone)
//
//   useEffect(() => {
//     if (isSubmitSuccessful) {
//       reset()
//       setPhone('')
//       setIsBlurredPhone(false)
//     }
//   }, [isSubmitSuccessful, reset])
//
//   // Видалення повідомлення через 5 секунд
//   useEffect(() => {
//     if (toastMessage) {
//       const timer = setTimeout(() => {
//         setToastMessage(null)
//       }, 5000)
//       return () => clearTimeout(timer)
//     }
//   }, [toastMessage])
//
//   // Функція відправки форми
//   const onSubmit = async ({ name }) => {
//     if (!isPhoneValid(phone)) {
//       setToastMessage({
//         type: 'error',
//         text: 'Будь ласка, введіть правильний номер телефону.',
//       })
//       return
//     }
//
//     setToastMessage({ type: 'pending', text: 'Відправка...' })
//
//     const msg = { name: name, phone: phone }
//     const resp = await sendInTg(msg)
//
//     if (resp.ok) {
//       setToastMessage({ type: 'success', text: 'Форма успішно надіслана!' })
//     } else {
//       setToastMessage({
//         type: 'error',
//         text: 'Щось пішло не так. Оновіть сторінку та спробуйте ще раз.',
//       })
//     }
//   }
//
//   const redBord = {
//     borderBottom: '1px solid rgba(255,0,0, 1)',
//   }
//   const greenBord = isFirstForm
//     ? {
//         borderBottom: '1px solid rgba(159, 255, 159, 1)',
//       }
//     : {
//         borderBottom: '1px solid #145D51',
//       }
//
//   const isFormValid = errors.name === undefined && isValid
//
//   return (
//     <div className="customContainer">
//       <div className="banner_content_block">
//         <div>
//           <img
//             className="banner_img"
//             src={BannerImg || '/placeholder.svg'}
//             alt="BannerImg"
//           />
//         </div>
//         <div className="banner_content_right">
//           <div>
//             <p className="banner_main_text">
//               <span className="banner_main_text_span">Комерційні</span> <br />{' '}
//               приміщення з терасами
//             </p>
//           </div>
//           <div className="banner_icon_blocks">
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon1 || '/placeholder.svg'}
//                   alt="BannerIcon1"
//                 />
//                 <p className="banner_icon_top_text">Площа</p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   від 38,40 м2 до 160,45 м2
//                 </p>
//               </div>
//             </div>
//             <div>
//               <img
//                 className="banner_icon_line"
//                 src={BannerLine || '/placeholder.svg'}
//                 alt="BannerLine"
//               />
//             </div>
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon2 || '/placeholder.svg'}
//                   alt="BannerIcon2"
//                 />
//                 <p className="banner_icon_top_text">Розтермінування </p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   до вересня 2026-го року
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div>
//             <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(errors?.name && touchedFields?.name
//                       ? redBord
//                       : !errors?.name && touchedFields?.name
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <input
//                     type="text"
//                     id={'name'}
//                     placeholder="Імʼя"
//                     className={style.form_box__wrap_inp}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                     {...register('name', {
//                       required: true,
//                       minLength: 2,
//                       maxLength: 30,
//                     })}
//                     autoComplete={'off'}
//                   />
//                 </div>
//               </div>
//
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(!isValid && isBlurredPhone
//                       ? redBord
//                       : isValid && isBlurredPhone
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <ReactInputMask
//                     mask="+38 (099) 999-99-99"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     onBlur={() => setIsBlurredPhone(true)}
//                     className={style.form_box__wrap_inp}
//                     placeholder={'+38 (095) 000-00-00'}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                   />
//                 </div>
//               </div>
//               <button
//                 className={`${style.form_btnnew} ${
//                   isFormValid ? style.form_btn_active : style.form_btn_disabled
//                 }`}
//                 disabled={!isFormValid}
//               >
//                 Надіслати
//               </button>
//             </form>
//             {toastMessage && (
//               <div
//                 className={`${style.toast} ${
//                   toastMessage.type === 'success'
//                     ? style.toastSuccess
//                     : toastMessage.type === 'error'
//                     ? style.toastError
//                     : style.toastPending
//                 }`}
//               >
//                 {toastMessage.text}
//               </div>
//             )}
//             <div>
//               <p className="banner_bottom_form_text">
//                 Залишайте контактні данні щоб отримати індивідіуальну пропозицію
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
//
// export default Banner

// import { useEffect, useState } from 'react'
// import './banner.css'
// import {
//   BannerIcon1,
//   BannerIcon2,
//   BannerImg,
//   BannerLine,
// } from '../../Assets/Images/Banner'
// import style from '../Form/Form.module.css'
// import ReactInputMask from 'react-input-mask'
// import { useForm } from 'react-hook-form'
// import { PhoneNumberUtil } from 'google-libphonenumber'
// import { sendInTg } from '../../Constants'
//
// const Banner = ({ form, id }) => {
//   const isFirstForm = form === '1'
//
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { isSubmitSuccessful, errors, touchedFields },
//     watch,
//   } = useForm({ mode: 'all' })
//
//   const [phone, setPhone] = useState('')
//   const [isBlurredPhone, setIsBlurredPhone] = useState(false)
//   const [isBlurredName, setIsBlurredName] = useState(false)
//   const [toastMessage, setToastMessage] = useState(null)
//   const [nameError, setNameError] = useState('')
//   const [phoneError, setPhoneError] = useState('')
//
//   const watchName = watch('name')
//
//   // Функція перевірки валідності номера
//   const isPhoneValid = (phone) => {
//     const phoneUtil = PhoneNumberUtil.getInstance()
//     try {
//       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
//     } catch (error) {
//       return false
//     }
//   }
//   const isValid = isPhoneValid(phone)
//
//   useEffect(() => {
//     if (isSubmitSuccessful) {
//       reset()
//       setPhone('')
//       setIsBlurredPhone(false)
//       setIsBlurredName(false)
//       setNameError('')
//       setPhoneError('')
//     }
//   }, [isSubmitSuccessful, reset])
//
//   // Видалення повідомлення через 5 секунд
//   useEffect(() => {
//     if (toastMessage) {
//       const timer = setTimeout(() => {
//         setToastMessage(null)
//       }, 5000)
//       return () => clearTimeout(timer)
//     }
//   }, [toastMessage])
//
//   useEffect(() => {
//     if (isBlurredName) {
//       if (!watchName) {
//         setNameError('Введіть ваше імʼя')
//       } else if (watchName.length < 2 || watchName.length > 30) {
//         setNameError("Ім'я повинно містити від 2 до 30 символів")
//       } else {
//         setNameError('')
//       }
//     }
//   }, [watchName, isBlurredName])
//
//   // Функція відправки форми
//   const onSubmit = async ({ name }) => {
//     if (!isPhoneValid(phone)) {
//       setToastMessage({
//         type: 'error',
//         text: 'Будь ласка, введіть правильний номер телефону.',
//       })
//       return
//     }
//
//     setToastMessage({ type: 'pending', text: 'Відправка...' })
//
//     const msg = { name: name, phone: phone }
//     const resp = await sendInTg(msg)
//
//     if (resp.ok) {
//       setToastMessage({ type: 'success', text: 'Форма успішно надіслана!' })
//     } else {
//       setToastMessage({
//         type: 'error',
//         text: 'Щось пішло не так. Оновіть сторінку та спробуйте ще раз.',
//       })
//     }
//   }
//
//   const redBord = {
//     borderBottom: '1px solid rgba(255,0,0, 1)',
//   }
//   const greenBord = isFirstForm
//     ? {
//         borderBottom: '1px solid rgba(159, 255, 159, 1)',
//       }
//     : {
//         borderBottom: '1px solid #145D51',
//       }
//
//   const isFormValid = !nameError && isValid && isBlurredPhone && isBlurredName
//
//   const handleNameBlur = () => {
//     setIsBlurredName(true)
//   }
//
//   const handlePhoneBlur = () => {
//     setIsBlurredPhone(true)
//     if (!isValid) {
//       setPhoneError('Введіть номер телефону')
//     } else {
//       setPhoneError('')
//     }
//   }
//
//   return (
//     <div className="customContainer">
//       <div className="banner_content_block">
//         <div>
//           <img
//             className="banner_img"
//             src={BannerImg || '/placeholder.svg'}
//             alt="BannerImg"
//           />
//         </div>
//         <div className="banner_content_right">
//           <div>
//             <p className="banner_main_text">
//               <span className="banner_main_text_span">Комерційні</span> <br />{' '}
//               приміщення з терасами
//             </p>
//           </div>
//           <div className="banner_icon_blocks">
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon1 || '/placeholder.svg'}
//                   alt="BannerIcon1"
//                 />
//                 <p className="banner_icon_top_text">Площа</p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   від 38,40 м2 до 160,45 м2
//                 </p>
//               </div>
//             </div>
//             <div>
//               <img
//                 className="banner_icon_line"
//                 src={BannerLine || '/placeholder.svg'}
//                 alt="BannerLine"
//               />
//             </div>
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon2 || '/placeholder.svg'}
//                   alt="BannerIcon2"
//                 />
//                 <p className="banner_icon_top_text">Розтермінування </p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   до вересня 2026-го року
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div>
//             <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(nameError && isBlurredName
//                       ? redBord
//                       : !nameError && isBlurredName
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <input
//                     type="text"
//                     id={'name'}
//                     placeholder="Імʼя"
//                     className={style.form_box__wrap_inp}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                     {...register('name', {
//                       required: true,
//                       minLength: 2,
//                       maxLength: 30,
//                     })}
//                     autoComplete={'off'}
//                     onBlur={handleNameBlur}
//                   />
//                 </div>
//                 {nameError && isBlurredName && (
//                   <p className={style.error_message}>{nameError}</p>
//                 )}
//               </div>
//
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(!isValid && isBlurredPhone
//                       ? redBord
//                       : isValid && isBlurredPhone
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <ReactInputMask
//                     mask="+38 (099) 999-99-99"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     onBlur={handlePhoneBlur}
//                     className={style.form_box__wrap_inp}
//                     placeholder={'+38 (095) 000-00-00'}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                   />
//                 </div>
//                 {phoneError && isBlurredPhone && (
//                   <p className={style.error_message}>{phoneError}</p>
//                 )}
//               </div>
//               <button
//                 className={`${style.form_btnnew} ${
//                   isFormValid ? style.form_btn_active : style.form_btn_disabled
//                 }`}
//                 disabled={!isFormValid}
//               >
//                 Надіслати
//               </button>
//             </form>
//             {toastMessage && (
//               <div
//                 className={`${style.toast} ${
//                   toastMessage.type === 'success'
//                     ? style.toastSuccess
//                     : toastMessage.type === 'error'
//                     ? style.toastError
//                     : style.toastPending
//                 }`}
//               >
//                 {toastMessage.text}
//               </div>
//             )}
//             <div>
//               <p className="banner_bottom_form_text">
//                 Залишайте контактні данні щоб отримати індивідіуальну пропозицію
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
//
// export default Banner

// import { useEffect, useState } from 'react'
// import './banner.css'
// import {
//   BannerIcon1,
//   BannerIcon2,
//   BannerImg,
//   BannerLine,
// } from '../../Assets/Images/Banner'
// import style from '../Form/Form.module.css'
// import ReactInputMask from 'react-input-mask'
// import { useForm } from 'react-hook-form'
// import { PhoneNumberUtil } from 'google-libphonenumber'
// import { sendInTg } from '../../Constants'
//
// const Banner = ({ form, id }) => {
//   const isFirstForm = form === '1'
//
//   const {
//     register,
//     handleSubmit,
//     reset,
//     formState: { isSubmitSuccessful, errors, touchedFields },
//     watch,
//   } = useForm({ mode: 'all' })
//
//   const [phone, setPhone] = useState('')
//   const [isBlurredPhone, setIsBlurredPhone] = useState(false)
//   const [isBlurredName, setIsBlurredName] = useState(false)
//   const [toastMessage, setToastMessage] = useState(null)
//   const [nameError, setNameError] = useState('')
//   const [phoneError, setPhoneError] = useState('')
//
//   const watchName = watch('name')
//
//   // Функція перевірки валідності номера
//   const isPhoneValid = (phone) => {
//     const phoneUtil = PhoneNumberUtil.getInstance()
//     try {
//       return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone))
//     } catch (error) {
//       return false
//     }
//   }
//   const isValid = isPhoneValid(phone)
//
//   useEffect(() => {
//     if (isSubmitSuccessful) {
//       reset()
//       setPhone('')
//       setIsBlurredPhone(false)
//       setIsBlurredName(false)
//       setNameError('')
//       setPhoneError('')
//     }
//   }, [isSubmitSuccessful, reset])
//
//   // Видалення повідомлення через 5 секунд
//   useEffect(() => {
//     if (toastMessage) {
//       const timer = setTimeout(() => {
//         setToastMessage(null)
//       }, 5000)
//       return () => clearTimeout(timer)
//     }
//   }, [toastMessage])
//
//   useEffect(() => {
//     if (isBlurredName) {
//       if (!watchName) {
//         setNameError('Введіть ваше імʼя')
//       } else if (watchName.length < 2 || watchName.length > 30) {
//         setNameError('Має містити від 2 до 30 символів')
//       } else {
//         setNameError('')
//       }
//     }
//   }, [watchName, isBlurredName])
//
//   useEffect(() => {
//     if (isBlurredPhone) {
//       if (!isValid) {
//         setPhoneError('Введіть номер телефону')
//       } else {
//         setPhoneError('')
//       }
//     }
//   }, [isBlurredPhone, isValid]) // Removed unnecessary 'phone' dependency
//
//   // Функція відправки форми
//   const onSubmit = async ({ name }) => {
//     if (!isPhoneValid(phone)) {
//       setToastMessage({
//         type: 'error',
//         text: 'Будь ласка, введіть правильний номер телефону.',
//       })
//       return
//     }
//
//     setToastMessage({ type: 'pending', text: 'Відправка...' })
//
//     const msg = { name: name, phone: phone }
//     const resp = await sendInTg(msg)
//
//     if (resp.ok) {
//       setToastMessage({ type: 'success', text: 'Форма успішно надіслана!' })
//     } else {
//       setToastMessage({
//         type: 'error',
//         text: 'Щось пішло не так. Оновіть сторінку та спробуйте ще раз.',
//       })
//     }
//   }
//
//   const redBord = {
//     borderBottom: '1px solid rgba(255,0,0, 1)',
//   }
//   const greenBord = isFirstForm
//     ? {
//         borderBottom: '1px solid rgba(159, 255, 159, 1)',
//       }
//     : {
//         borderBottom: '1px solid #145D51',
//       }
//
//   const isFormValid = !nameError && isValid && isBlurredPhone && isBlurredName
//
//   const handleNameBlur = () => {
//     setIsBlurredName(true)
//   }
//
//   const handlePhoneBlur = () => {
//     setIsBlurredPhone(true)
//   }
//
//   return (
//     <div className="customContainer">
//       <div className="banner_content_block">
//         <div>
//           <img
//             className="banner_img"
//             src={BannerImg || '/placeholder.svg'}
//             alt="BannerImg"
//           />
//         </div>
//         <div className="banner_content_right">
//           <div>
//             <p className="banner_main_text">
//               <span className="banner_main_text_span">Комерційні</span> <br />{' '}
//               приміщення з терасами
//             </p>
//           </div>
//           <div className="banner_icon_blocks">
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon1 || '/placeholder.svg'}
//                   alt="BannerIcon1"
//                 />
//                 <p className="banner_icon_top_text">Площа</p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   від 38,40 м2 до 160,45 м2
//                 </p>
//               </div>
//             </div>
//             <div>
//               <img
//                 className="banner_icon_line"
//                 src={BannerLine || '/placeholder.svg'}
//                 alt="BannerLine"
//               />
//             </div>
//             <div className="banner_icon_block">
//               <div className="banner_icon_top">
//                 <img
//                   className="banner_icon"
//                   src={BannerIcon2 || '/placeholder.svg'}
//                   alt="BannerIcon2"
//                 />
//                 <p className="banner_icon_top_text">Розтермінування </p>
//               </div>
//               <div>
//                 <p className="banner_icon_bottom_text">
//                   до вересня 2026-го року
//                 </p>
//               </div>
//             </div>
//           </div>
//           <div>
//             <form onSubmit={handleSubmit(onSubmit)} className={style.form}>
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(nameError && isBlurredName
//                       ? redBord
//                       : !nameError && isBlurredName
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <input
//                     type="text"
//                     id={'name'}
//                     placeholder="Імʼя"
//                     className={style.form_box__wrap_inp}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                     {...register('name', {
//                       required: true,
//                       minLength: 2,
//                       maxLength: 30,
//                     })}
//                     autoComplete={'off'}
//                     onBlur={handleNameBlur}
//                   />
//                 </div>
//                 {nameError && isBlurredName && (
//                   <p className={style.error_message}>{nameError}</p>
//                 )}
//               </div>
//
//               <div className={style.form_box}>
//                 <div
//                   className={style.form_box__wrap}
//                   style={{
//                     ...(!isValid && isBlurredPhone
//                       ? redBord
//                       : isValid && isBlurredPhone
//                       ? greenBord
//                       : null),
//                     ...(isFirstForm ? {} : { borderBottomColor: '#145D51' }),
//                   }}
//                 >
//                   <ReactInputMask
//                     mask="+38 (099) 999-99-99"
//                     value={phone}
//                     onChange={(e) => setPhone(e.target.value)}
//                     onBlur={handlePhoneBlur}
//                     className={style.form_box__wrap_inp}
//                     placeholder={'+38 (095) 000-00-00'}
//                     style={isFirstForm ? {} : { color: '#145D51' }}
//                   />
//                 </div>
//                 {phoneError && isBlurredPhone && (
//                   <p className={style.error_message}>{phoneError}</p>
//                 )}
//               </div>
//               <button
//                 className={`${style.form_btnnew} ${
//                   isFormValid ? style.form_btn_active : style.form_btn_disabled
//                 }`}
//                 disabled={!isFormValid}
//               >
//                 Надіслати
//               </button>
//             </form>
//             {toastMessage && (
//               <div
//                 className={`${style.toast} ${
//                   toastMessage.type === 'success'
//                     ? style.toastSuccess
//                     : toastMessage.type === 'error'
//                     ? style.toastError
//                     : style.toastPending
//                 }`}
//               >
//                 {toastMessage.text}
//               </div>
//             )}
//             <div>
//               <p className="banner_bottom_form_text">
//                 Залишайте контактні данні щоб отримати індивідіуальну пропозицію
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }
//
// export default Banner
