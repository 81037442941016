import './App.css'
import './Assets/Fonts/stylesheet.css'
import Why from './Modules/WhySab/Why'
import SabInvestment from './Modules/SabInvestment/SabInvestment'
import SabKreativ from './Modules/SabKreativ/SabKreativ'
import { MainBlock } from './Modules/MainBlock/MainBlock'
import { Form } from './Modules/Form/Form'
import { ToastContainer } from 'react-toastify'
import { Cases } from './Modules/Cases/Cases'
import Logos from './Modules/Logos/Logos'
import Footer from './Modules/Footer/Footer'
import DevelopedBy from './Modules/Developedby/DevelopedBy'
import { SabBuilding } from './Modules/SABBuilding/SABBuilding'
import FacebookConversionsAPI from './Components/FacebookConversionsAPI/FacebookConversionsAPI'
import { accessToken, pixelId } from './Constants'
import Banner from './Modules/Banner/Banner'

function App() {
  return (
    <div className="App">
      <FacebookConversionsAPI pixelId={pixelId} accessToken={accessToken} />
      <ToastContainer />
      <MainBlock />
      <SabBuilding />
      <Banner form={'1'} id={'form1'} />
      <Why />
      <Form form={'1'} id={'form1'} />
      <SabInvestment />
      <SabKreativ />
      <Cases />
      <Logos />
      <Form form={'2'} id={'form2'} />
      <Footer />
      <DevelopedBy />
    </div>
  )
}

export default App
